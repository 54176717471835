.brand-logo {
  width: 10rem;
  pointer-events: none;
  margin: 1rem 0 2rem;
}

.App {
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.text-center {
  text-align: center;
}

.quotation-container label {
  font-size: 0.9rem;
  margin-right: 0.5rem;
}

@media screen and (max-width: 450px) {
  .brand-logo {
    width: 7rem;
  }
}
