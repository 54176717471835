.login-container {
  margin-top: 3rem;
  padding: 2rem;
}

.login-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-header h1 {
  margin-bottom: 2rem;
  font-size: 1.6rem;
  color: var(--primary-brand-color);
  text-align: center;
}

.outline-email-input,
.outline-password-input {
  margin: 0.5rem 0;
}

.outline-email-input label,
.outline-password-input label {
  font-size: 0.9rem;
  color: var(--primary-brand-color);
  font-weight: 400;
  margin-bottom: 2rem;
}

.outline-email-input input,
.outline-password-input input {
  width: 100%;
  height: 2.3rem;
  margin: 0.4rem auto 0.4rem;
  font-size: 1rem;
  padding: 0.5rem 0.6rem;
  border-radius: 4px;
  border: 1px solid gray;
}

.btn {
  margin: 0.5rem 0;
  font-size: 1.2rem;
  border-radius: 4px;
  border: none;
  text-align: center;
  cursor: pointer;
  outline: none;
  display: inline-block;
  text-decoration: none;
  width: 100%;
  padding: 0.8rem 0.8rem;
}

.primary-btn {
  color: white;
  background-color: var(--primary-brand-color);
}

.primary-outline-btn {
  color: var(--primary-brand-color);
  border: 1px solid var(--primary-brand-color);
}

.login-text {
  margin-top: 0.6rem;
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
}

@media screen and (max-width: 450px) {
  .login-container {
    margin-top: 0rem;
    padding: 2rem 1rem;
  }

  .login-header h1 {
    margin-bottom: 1rem;
    font-size: 1.2rem;
  }
}
